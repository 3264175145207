var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false",
                },
              ],
              attrs: { md: "3" },
            },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "sku-form-has-variations",
                  type: "switch",
                  label: _vm.$t("Possui variação?"),
                  disabled: !!_vm.selectedVariants.length,
                },
                on: { input: _vm.findVariations },
                model: {
                  value: _vm.product.hasVariations,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "hasVariations", $$v)
                  },
                  expression: "product.hasVariations",
                },
              }),
            ],
            1
          ),
          _vm.product.hasVariations
            ? _c(
                "b-col",
                { attrs: { md: "9" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.$t("Quais variações?") } },
                    [
                      _c(
                        "b-form-checkbox-group",
                        {
                          attrs: { "disabled-field": "notEnabled" },
                          model: {
                            value: _vm.product.variations,
                            callback: function ($$v) {
                              _vm.$set(_vm.product, "variations", $$v)
                            },
                            expression: "product.variations",
                          },
                        },
                        [
                          _vm._l(_vm.variationsList, function (v) {
                            return [
                              _c(
                                "b-form-checkbox",
                                {
                                  key: v.id,
                                  attrs: {
                                    id: "sku-form-variation-" + v.id,
                                    value: v.id,
                                    disabled: _vm.disableChecked(v.name),
                                  },
                                },
                                [_vm._v(" " + _vm._s(v.name) + " ")]
                              ),
                            ]
                          }),
                          !_vm.variationsList.length
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("Nenhuma variação cadastrada"))
                                ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _vm.product.hasVariations
                ? _c("e-button", {
                    staticClass: "my-1",
                    attrs: {
                      variant: "primary",
                      icon: "plus-circle",
                      text: _vm.$t("Adicionar SKU"),
                    },
                    on: { click: _vm.showSidebarCreate },
                  })
                : _vm._e(),
              _vm._l(_vm.product.skus, function (sku, index) {
                return _c(
                  "div",
                  { key: index, attrs: { sku: sku } },
                  [
                    _c("price-table", {
                      ref: "priceTable_" + index,
                      refInFor: true,
                      attrs: { "sku-index": index, "require-price": false },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("e-sidebar", {
        ref: "variation-sidebar",
        attrs: {
          show: _vm.showSidebar,
          title: _vm.isUpdate ? _vm.$t("Alterar SKU") : _vm.$t("Novo SKU"),
          width: "400px",
        },
        on: {
          save: _vm.upInsertSku,
          hidden: function ($event) {
            _vm.showSidebar = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "FormulateForm",
                  {
                    attrs: { name: "variationForm" },
                    on: { submit: _vm.upInsertSku },
                    model: {
                      value: _vm.variationFormData,
                      callback: function ($$v) {
                        _vm.variationFormData = $$v
                      },
                      expression: "variationFormData",
                    },
                  },
                  [
                    _c(
                      "b-row",
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "sku-form-id",
                            type: "hidden",
                            name: "id",
                          },
                        }),
                        _vm.getVariantsSelected.length
                          ? _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c(
                                  "b-card",
                                  { attrs: { title: _vm.$t("Variações") } },
                                  [
                                    _c(
                                      "b-row",
                                      _vm._l(
                                        _vm.getVariantsSelected,
                                        function (variation) {
                                          return _c(
                                            "b-col",
                                            {
                                              key: variation.name,
                                              attrs: { md: "12" },
                                            },
                                            [
                                              variation.valueType === "Dropdown"
                                                ? [
                                                    _c("FormulateInput", {
                                                      staticClass: "required",
                                                      attrs: {
                                                        id:
                                                          "sku-form-variation_" +
                                                          variation.id,
                                                        type: "vue-select",
                                                        name:
                                                          "variation_" +
                                                          variation.id,
                                                        label: variation.name,
                                                        options:
                                                          variation.options,
                                                        placeholder:
                                                          _vm.$t("Selecione"),
                                                        validation: "required",
                                                      },
                                                    }),
                                                  ]
                                                : [
                                                    _c("FormulateInput", {
                                                      staticClass: "required",
                                                      attrs: {
                                                        id:
                                                          "sku-form-variation-" +
                                                          variation.id,
                                                        type:
                                                          variation.valueType ===
                                                            "Integer" ||
                                                          variation.valueType ===
                                                            "Decimal"
                                                            ? "number"
                                                            : "text",
                                                        name:
                                                          "variation_" +
                                                          variation.id,
                                                        label: variation.name,
                                                        validation: "required",
                                                      },
                                                    }),
                                                  ],
                                            ],
                                            2
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _c(
                              "b-card",
                              { attrs: { title: _vm.$t("Preços") } },
                              [
                                _c(
                                  "b-row",
                                  _vm._l(_vm.localSkuPrices, function (price) {
                                    return _c(
                                      "b-col",
                                      {
                                        key: price.priceTable.id,
                                        attrs: { md: "12" },
                                      },
                                      [
                                        _c("FormulateInput", {
                                          class: {
                                            required:
                                              price.priceTable.isDefault,
                                          },
                                          attrs: {
                                            id:
                                              "sku-form-price-" +
                                              price.priceTable.id,
                                            name:
                                              "price_" + price.priceTable.id,
                                            label: price.priceTable.name,
                                            type: "text-number",
                                            currency: "R$",
                                            precision: 2,
                                            validation: price.priceTable
                                              .isDefault
                                              ? "required|min:0.01"
                                              : "",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }