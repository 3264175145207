var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _vm.product
        ? _c(
            "FormulateForm",
            {
              ref: "formProduct",
              attrs: { name: "formProduct" },
              on: { submit: _vm.localSaveProduct },
            },
            [
              _c(
                "b-card-actions",
                {
                  attrs: {
                    "action-collapse": "",
                    "show-loading": _vm.fetching || _vm.saving,
                    title: _vm.productDescription
                      ? "" +
                        (_vm.product.id ? "#" + _vm.product.id + " - " : "") +
                        _vm.productDescription
                      : _vm.$t("Produto"),
                  },
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "3" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    { staticClass: "w-100 border-0" },
                                    [
                                      _c("b-form-radio-group", {
                                        staticClass: "w-100 border-0",
                                        attrs: {
                                          id: "active",
                                          options: [
                                            { value: true, text: "Ativo" },
                                            { value: false, text: "Inativo" },
                                          ],
                                          "button-variant": "primary",
                                          size: "md",
                                          buttons: "",
                                          disabled: _vm.isReadOnly,
                                        },
                                        model: {
                                          value: _vm.product.active,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.product, "active", $$v)
                                          },
                                          expression: "product.active",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-tabs",
                                    { attrs: { fill: "" } },
                                    [
                                      _c(
                                        "b-tab",
                                        {
                                          attrs: {
                                            active: "",
                                            title: _vm.$t("Quadrada"),
                                          },
                                        },
                                        [
                                          _c("FormulateInput", {
                                            attrs: {
                                              id: "image-square-local",
                                              type: _vm.isReadOnly
                                                ? "image"
                                                : "uploader",
                                              deletable: "true",
                                              "max-files": "1",
                                              meta: false,
                                            },
                                            model: {
                                              value: _vm.imageSquareLocal,
                                              callback: function ($$v) {
                                                _vm.imageSquareLocal = $$v
                                              },
                                              expression: "imageSquareLocal",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-tab",
                                        { attrs: { title: _vm.$t("Wide") } },
                                        [
                                          _c("FormulateInput", {
                                            attrs: {
                                              id: "image-wide-local",
                                              type: _vm.isReadOnly
                                                ? "image"
                                                : "uploader",
                                              deletable: "true",
                                              "max-files": "1",
                                              meta: false,
                                            },
                                            model: {
                                              value: _vm.imageWideLocal,
                                              callback: function ($$v) {
                                                _vm.imageWideLocal = $$v
                                              },
                                              expression: "imageWideLocal",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "overflow-x": "auto" } },
                                    [
                                      _c(
                                        "barcode",
                                        {
                                          staticClass: "text-center",
                                          attrs: {
                                            value:
                                              _vm.product.ean || 1234567890,
                                          },
                                        },
                                        [_vm._v(" EAN ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  !_vm.isReadOnly
                                    ? _c("FormulateInput", {
                                        staticClass: "mt-1",
                                        attrs: {
                                          id: "ean",
                                          label: _vm.$t(
                                            "Código de Barras (EAN/GTIN)"
                                          ),
                                          type: "text",
                                          inputmode: "numeric",
                                          pattern: /[0-9]*/,
                                          maxlength: "15",
                                        },
                                        model: {
                                          value: _vm.product.ean,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.product,
                                              "ean",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "product.ean",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "9" } },
                        [
                          _c("product-form", {
                            ref: "productForm",
                            attrs: {
                              "purchase-order-product":
                                _vm.purchaseOrderProduct,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c(
                        "b-card-actions",
                        {
                          attrs: {
                            "action-collapse": "",
                            title: _vm.$t("Preços"),
                            "show-loading":
                              _vm.fetching ||
                              _vm.fetchingPriceTables ||
                              _vm.saving,
                          },
                        },
                        [_c("sku-form", { ref: "skuForm" })],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.product.tax
                ? _c(
                    "b-card-actions",
                    {
                      attrs: {
                        "action-collapse": "",
                        title: _vm.$t("Fiscal"),
                        "show-loading": _vm.fetching || _vm.saving,
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "b-row",
                            { attrs: { "align-v": "center" } },
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "5" } },
                                        [
                                          _c("FormulateInput", {
                                            attrs: {
                                              id: "tax-type",
                                              type: _vm.isReadOnly
                                                ? "label"
                                                : "vue-select",
                                              label: _vm.$t("Tipo"),
                                              placeholder: _vm.$t("Selecione"),
                                              options: _vm.productTaxTypes(),
                                            },
                                            model: {
                                              value:
                                                _vm.product.tax.productTaxType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.product.tax,
                                                  "productTaxType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "product.tax.productTaxType",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "7" } },
                                        [
                                          _c("FormulateInput", {
                                            class: _vm.isSaleClassification
                                              ? "required"
                                              : "",
                                            attrs: {
                                              id: "ncm",
                                              type: _vm.isReadOnly
                                                ? "label"
                                                : "select-searchable",
                                              label: _vm.$t("NCM"),
                                              placeholder: _vm.$t(
                                                "Digite para pesquisar"
                                              ),
                                              options: _vm.getComboNcms,
                                              "start-search":
                                                _vm.product.tax.ncmCode,
                                              autofocus: "",
                                              validation:
                                                _vm.isSaleClassification
                                                  ? "required"
                                                  : "",
                                              instruction: _vm.$t(
                                                "Digite pelo menos 3 numeros ou letras para iniciar a pesquisa"
                                              ),
                                            },
                                            on: { fetchSearch: _vm.ncmSearch },
                                            model: {
                                              value: _vm.product.tax.ncmCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.product.tax,
                                                  "ncmCode",
                                                  $$v
                                                )
                                              },
                                              expression: "product.tax.ncmCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "5" } },
                                        [
                                          _c("FormulateInput", {
                                            attrs: {
                                              id: "origin",
                                              type: _vm.isReadOnly
                                                ? "label"
                                                : "vue-select",
                                              label: _vm.$t("Origem"),
                                              placeholder: _vm.$t("Selecione"),
                                              options: _vm.productOrigins(),
                                            },
                                            model: {
                                              value:
                                                _vm.product.tax.productOrigin,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.product.tax,
                                                  "productOrigin",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "product.tax.productOrigin",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "7" } },
                                        [
                                          _c("FormulateInput", {
                                            attrs: {
                                              id: "cest-id",
                                              type: _vm.isReadOnly
                                                ? "label"
                                                : "select-searchable",
                                              instruction: _vm.$t(
                                                "Digite pelo menos 3 numeros ou letras para iniciar a pesquisa"
                                              ),
                                              placeholder: _vm.$t(
                                                "Digite para pesquisar"
                                              ),
                                              label: _vm.$t("CEST"),
                                              options: _vm.getComboCests,
                                            },
                                            on: { fetchSearch: _vm.cestSearch },
                                            model: {
                                              value: _vm.product.tax.cestId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.product.tax,
                                                  "cestId",
                                                  $$v
                                                )
                                              },
                                              expression: "product.tax.cestId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c("e-crud-actions", {
                attrs: {
                  "show-save": !_vm.isReadOnly,
                  "show-edit": _vm.isReadOnly && _vm.$can("Update", "Product"),
                  busy: _vm.saving,
                },
                on: {
                  save: _vm.localSaveProduct,
                  cancel: function ($event) {
                    return _vm.$router.back()
                  },
                  edit: _vm.edit,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }