<template>
  <div>
    <b-row>
      <b-col
        v-show="false"
        md="3"
      >
        <FormulateInput
          id="sku-form-has-variations"
          v-model="product.hasVariations"
          type="switch"
          :label="$t('Possui variação?')"
          :disabled="!!selectedVariants.length"
          @input="findVariations"
        />
      </b-col>

      <b-col
        v-if="product.hasVariations"
        md="9"
      >
        <b-form-group :label="$t('Quais variações?')">
          <b-form-checkbox-group
            v-model="product.variations"
            disabled-field="notEnabled"
          >
            <template v-for="v in variationsList">
              <b-form-checkbox
                :id="`sku-form-variation-${v.id}`"
                :key="v.id"
                :value="v.id"
                :disabled="disableChecked(v.name)"
              >
                {{ v.name }}
              </b-form-checkbox>
            </template>
            <span v-if="!variationsList.length">{{ $t('Nenhuma variação cadastrada') }}</span>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <e-button
          v-if="product.hasVariations"
          class="my-1"
          variant="primary"
          icon="plus-circle"
          :text="$t('Adicionar SKU')"
          @click="showSidebarCreate"
        />

        <div
          v-for="(sku, index) in product.skus"
          :key="index"
          :sku="sku"
        >
          <price-table
            :ref="`priceTable_${index}`"
            :sku-index="index"
            :require-price="false"
          />
        </div>

        <!-- <b-table
          ref="variationsTable"
          show-empty
          responsive
          striped
          class="bordered"          :fields="fields"
          :items="product.skus"
          primary-key="sku"
        >
          <template #cell(action)="row">
            <e-grid-actions
              :show-delete="row.item.variations.length"
              @update="showSidebarUpdate(row)"
              @delete="deleteVariant(row)"
            />
          </template>
          <template #cell(variation)="row">
            <div v-if="!row.item.variations.length">
              {{ $t('Sem variação') }}
            </div>
            <div
              v-for="variation in row.item.variations"
              v-else
              :key="variation.variationId"
            >
              <b>{{ variation.variationName }}:</b>&nbsp;
              <span>{{
                variation.variationValue && variation.variationValue.value
                  ? variation.variationValue.value
                  : variation.variationOpenValue
              }}</span>
            </div>
          </template>
          <template #cell(price)="row">
            <table border="0">
              <tr
                v-for="price in row.item.prices.filter((p) => !p.store.id)"
                :key="price.priceTable.id"
              >
                <td style="border: 0">
                  <b>{{ price.priceTable.name }}:</b>
                </td>
                <td style="border: 0">
                  {{ price.price | currency }}
                </td>
              </tr>
            </table>
          </template>
        </b-table> -->
      </b-col>
    </b-row>

    <e-sidebar
      ref="variation-sidebar"
      :show="showSidebar"
      :title="isUpdate ? $t(`Alterar SKU`) : $t(`Novo SKU`)"
      width="400px"
      @save="upInsertSku"
      @hidden="showSidebar = false"
    >
      <template #content>
        <FormulateForm
          v-model="variationFormData"
          name="variationForm"
          @submit="upInsertSku"
        >
          <b-row>
            <FormulateInput
              id="sku-form-id"
              type="hidden"
              name="id"
            />
            <b-col
              v-if="getVariantsSelected.length"
              md="12"
            >
              <b-card :title="$t('Variações')">
                <b-row>
                  <b-col
                    v-for="variation in getVariantsSelected"
                    :key="variation.name"
                    md="12"
                  >
                    <template v-if="variation.valueType === 'Dropdown'">
                      <FormulateInput
                        :id="`sku-form-variation_${variation.id}`"
                        type="vue-select"
                        :name="`variation_${variation.id}`"
                        :label="variation.name"
                        :options="variation.options"
                        :placeholder="$t('Selecione')"
                        validation="required"
                        class="required"
                      />
                    </template>
                    <template v-else>
                      <FormulateInput
                        :id="`sku-form-variation-${variation.id}`"
                        :type="
                          variation.valueType === 'Integer' || variation.valueType === 'Decimal'
                            ? 'number'
                            : 'text'
                        "
                        :name="`variation_${variation.id}`"
                        :label="variation.name"
                        validation="required"
                        class="required"
                      />
                    </template>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <!-- <template v-if="skuIndex >= 0"> -->
            <b-col md="12">
              <b-card :title="$t('Preços')">
                <b-row>
                  <b-col
                    v-for="price in localSkuPrices"
                    :key="price.priceTable.id"
                    md="12"
                  >
                    <FormulateInput
                      :id="`sku-form-price-${price.priceTable.id}`"
                      :name="`price_${price.priceTable.id}`"
                      :label="price.priceTable.name"
                      type="text-number"
                      currency="R$"
                      :precision="2"
                      :class="{ required: price.priceTable.isDefault }"
                      :validation="price.priceTable.isDefault ? 'required|min:0.01' : ''"
                    />
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <!-- </template> -->
          </b-row>
        </FormulateForm>
      </template>
    </e-sidebar>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  BRow,
  BCol,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormGroup,
  BTable,
  BCard,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { EButton, EGridActions, ESidebar } from '@/views/components'
import PriceTable from './PriceTable.vue'
// import SkuCard from './SkuCard.vue'

export default {
  name: 'VariationForm',

  components: {
    BRow,
    BCol,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormGroup,
    BTable,
    BCard,
    EButton,
    EGridActions,
    ESidebar,
    PriceTable,
    // SkuCard,
  },

  mixins: [],

  data() {
    return {
      variationFormData: {},
      showSidebar: false,
      saving: false,
      isUpdate: false,
      updateIndex: null,
      variantsFields: [],
      skuIndex: -1,
      localSkuPrices: [],
    }
  },

  computed: {
    ...mapState('pages/catalog/products/productMaintain', [
      'product',
      'selectedVariants',
      'variationsList',
      'variationByName',
      'skuPrices',
      'priceTables',
    ]),
    ...mapGetters('pages/catalog/products/productMaintain', ['selectedVariants']),
    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Variação'),
          key: 'variation',
          // class: 'text-center',
          // thStyle: { width: '250px' },
        },
        {
          label: this.$t('Preços'),
          key: 'price',
          formatter: value => this.$options.filters.currency(value),
          tdClass: 'text-left',
          thStyle: { width: '180px' },
        },
      ]
    },
    // selected: {
    //   get() {
    //     return this.selectedVariants
    //   },
    //   set(value) {
    //     return this.setSelectedVariants(value)
    //   },
    // },

    getVariantsSelected() {
      return this.variationsList.filter(item =>
        // eslint-disable-next-line eqeqeq
        this.selectedVariants.some(id => id == item.id)
      )
    },
  },

  watch: {
    // selected(newVals, oldVals) {
    //   const hasAddedVal = oldVals.length < newVals.length
    //   if (hasAddedVal) {
    //     const nameAdded = newVals[newVals.length - 1]
    //     const variantObj = this.variationsList.find(vari => vari.name === nameAdded)
    //     this.addTableField(variantObj.name, variantObj.label)
    //   } else {
    //     const nameRemoved = oldVals.find(oldVal => !newVals.some(newVal => oldVal === newVal))
    //     this.removeTableField(nameRemoved)
    //   }
    // },
  },

  mounted() {
    if (this.selectedVariants.length > 0) {
      this.fetchComboVariants()
      this.variationsList
        .filter(vari => this.selectedVariants.some(name => vari.name === name))
        .forEach(vari => this.addTableField(vari.name, vari.label))
    }
  },

  methods: {
    ...mapActions('pages/catalog/products/productMaintain', [
      'addSku',
      'updateSku',
      'removeSku',
      'setSelectedVariants',
      'fetchComboVariants',
      'getSkuPrices',
    ]),

    getNewPrices() {
      const newPrices = []
      this.product.skus.forEach((sku, index) => {
        this.$refs[`priceTable_${index}`].forEach(priceTable => {
          const skuNewPrices = priceTable.getNewPrices()
          if (skuNewPrices.length) {
            newPrices.push(...skuNewPrices)
          }
        })
      })
      return newPrices
    },

    async getSkuFormData() {
      const formFields = {}
      this.getVariantsSelected.forEach(item => {
        formFields[`variation_${item.id}`] = undefined
      })

      this.localSkuPrices = await this.getSkuPrices(-1)

      this.priceTables.forEach(pt => {
        formFields[`price_${pt.id}`] = undefined
      })
      return formFields
    },

    async showSidebarCreate() {
      this.variationFormData = await this.getSkuFormData()
      this.isUpdate = false
      this.showSidebar = true
    },

    async showSidebarUpdate(row) {
      // console.log(this.getVariantsSelected, row)
      this.skuIndex = row.index
      const formFields = {
        sku: row.item.sku,
        id: row.item.id || null,
      }

      this.getVariantsSelected.forEach(item => {
        const variation = row.item.variations.find(v => v.variationId === item.id)
        formFields[`variation_${item.id}`] =
          variation?.variationValue?.id?.toString() || variation?.variationOpenValue
      })

      this.localSkuPrices = await this.getSkuPrices(this.skuIndex)

      this.localSkuPrices.forEach(p => {
        formFields[`price_${p.priceTable.id}`] = p.price
      })

      this.updateIndex = row.index
      this.isUpdate = true
      this.variationFormData = formFields
      this.showSidebar = true
    },

    addTableField(name, label) {
      const newFields = this.variantsFields
      newFields.push({
        label,
        key: name,
        formatter: (value, key, item) => {
          const variation = item.find(varItem => varItem.variationName === key)
          return variation.variationValue?.value || variation.variationOpenValue
        },
        class: 'text-center',
      })
      // this.fields = [...this.baseFields, ...this.variantsFields]
    },

    removeTableField(name) {
      const index = this.variantsFields.findIndex(field => field.key === name)
      if (index > -1) {
        this.variantsFields.splice(index, 1)

        this.fields = [...this.variantsFields, ...this.baseFields]
      }
    },

    upInsertSku() {
      const formData = this.variationFormData
      if (!this.isValidVariant(formData)) return

      const prices = []
      // Update prices
      Object.keys(formData)
        .filter(key => key.startsWith('price_'))
        .forEach(priceKey => {
          const priceTable = this.priceTables.find(
            pt => pt.id === parseInt(priceKey.split('_')[1], 10)
          )
          if (formData[priceKey] > 0) {
            prices.push({
              price: formData[priceKey],
              priceTable,
              store: { id: null },
            })
          }
          // priceTable.price = formData[priceKey]
        })

      // // Update prices
      // Object.keys(formData)
      //   .filter(key => key.startsWith('price_'))
      //   .forEach(priceKey => {
      //     const priceTable = this.priceTables.find(pt => pt.id === priceKey.split('_')[1])
      //     priceTable.price = formData[priceKey]
      //   })

      if (this.isUpdate) {
        this.updateSku({
          variations: {
            ...formData,
          },
          prices,
          index: this.updateIndex,
        })
        this.$refs.variationsTable.refresh()
      } else {
        this.addSku({
          variations: {
            // variationId: formData.variation.name,
            ...formData,
          },
          prices,
        })
      }

      this.showSidebar = false
      this.variationFormData = {}
    },

    async deleteVariant(row) {
      try {
        const confirm = await this.confirm()

        if (confirm) {
          // TODO: verificação antes de deletar (relacionamentos, verificar ID)
          // se n tiver id apenas tirar pela posição do index

          this.removeSku(row.index)

          this.showSuccess({
            message: this.$t('Variação removida com sucesso'),
          })
        }
      } catch (error) {
        this.showError({ error })
      }
    },

    findVariations(checked) {
      if (checked) {
        this.fetchComboVariants()
      }
    },

    disableChecked(id) {
      return (
        this.product.variations.length > 0 &&
        // eslint-disable-next-line eqeqeq
        this.selectedVariants.some(select => select == id) &&
        this.product.variations.some(vari => !!vari[id])
      )
    },

    // eslint-disable-next-line no-unused-vars
    isValidVariant(formData) {
      return true
      //   try {
      //     const isValid = true
      //     const storeVariations = this.product.variations
      //     const formDataKeyList = Object.keys(formData).filter(key => key !== 'sku')

      //     if (this.isUpdate) {
      //       storeVariations = storeVariations.filter(v => v.sku !== formData.sku)
      //     }

      //     for (let i = 0; i < storeVariations.length; i += 1) {
      //       const val = {}
      //       storeVariations[i].forEach(variation => {
      //         val[variation.variationName] =
      //           variation.variationValue?.id?.toString() || variation.variationOpenValue
      //       })
      //       isValid = formDataKeyList
      //         .map(fKey => val[fKey] === formData[fKey])
      //         .some(isInvalid => !isInvalid)

      //       if (!isValid) throw new Error('A combinação de variante já existe')
      //     }

      //     return true
      //   } catch (e) {
      //     this.showWarning({
      //       message: `<b>${e.message}</b>`,
      //     })
      //     return false
      //   }
    },
  },
}
</script>

<style></style>
